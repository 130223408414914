<template>
	<div>
		<div :class="{'activity-min-height': hasMinHeight}" class="activity-list" v-if="jobList.length > 0&&!showEmpty">
			<div @click="toJobDetail(item)" v-for="(item, index) in jobList" :key="index" class="activity-list-item">
				<div class="list-item-top">
					<div class="item-top-title">
						<span>{{(importArea != 'jobhour')?item.name:item.postName}}</span>
						<div v-if="importArea == 'student'">
							<img v-if="(!item.signUpStatus || item.signUpStatus == '')&&item.state==1"
								:src="require(`@/assets/images/mobile/student/status0.png`)" alt=""
								class="top-title-status">
							<img v-if="(!item.signUpStatus || item.signUpStatus == '')&&item.state!==1"
								:src="require(`@/assets/images/mobile/student/status4.png`)" alt=""
								class="top-title-status">
							<img v-if="item.signUpStatus == 'REGISTERING'"
								:src="require(`@/assets/images/mobile/student/status1.png`)" alt=""
								class="top-title-status">
							<img v-if="item.signUpStatus == 'ADMISSION'"
								:src="require(`@/assets/images/mobile/student/status2.png`)" alt=""
								class="top-title-status">
							<img v-if="item.signUpStatus == 'CANCEL'"
								:src="require(`@/assets/images/mobile/student/status3.png`)" alt=""
								class="top-title-status">
						</div>
						<img v-if="item.workingHourStatus+''&&importArea == 'teacher'&&item.workingHourStatus==2"
							src="@/assets/images/mobile/student/manhour1.png" alt=""
							class="top-title-status"> 
						<img v-if="item.workingHourStatus+''&&importArea == 'teacher'&&item.workingHourStatus<2"
							src="@/assets/images/mobile/student/manhour0.png" alt=""
							class="top-title-status"> 
						<!-- <img v-if="item.workingHourStatus+''&&importArea == 'teacher'"
							:src="require(`@/assets/images/mobile/student/manhour${item.workingHourStatus}.png`)" alt=""
							class="top-title-status"> -->
						<img v-if="item.state+''&&importArea == 'singin'&&item.state != 0"
							:src="require(`@/assets/images/mobile/student/singin${item.state}.png`)" alt=""
							class="top-title-status">
					</div>
					<div class="flex-box">
						<div>
							<span class="item-top-tag">{{item.postTypeName}}</span>
							<span class="item-top-tag" style="margin-left:0.08rem">{{item.schoolName}}</span>
						</div>
						<div class="total-time" v-if="item.totalWorkingHour||item.totalWorkingHour==0">工时：{{item.totalWorkingHour}}h</div>
					</div>
					
					
				</div>
				<div class="list-item-bottom">
					<div class="item-bottom-step">
						<span class="bottom-step-circle1"></span>
						<span class="bottom-step-line"></span>
						<span class="bottom-step-circle2"></span>
					</div>
					<div class="item-bottom-content">
						<!-- <span v-if="importArea != 'jobhour'">岗位时间：{{`${dateFormat(item,item.starTime)} 至${dateFormat(item,item.endTime)?' '+dateFormat(item,item.endTime):'今'}`}}</span> -->
						<span>岗位时间：{{item.postTime}}</span>
						<span>岗位地点：{{importArea != 'jobhour'?item.address:item.postAddress}}</span>
					</div>
				</div>
			</div>
		</div>
		<div v-if="jobList.length == 0&&showEmpty" class="activity-list-empty">
			<img src="@/assets/images/mobile/common/empty2.png" alt="">
			<span>{{emptyText}}</span>
		</div>
	</div>
</template>

<script>
	export default {
		inject:['reload'],
		props: {
			jobList: {
				type: Array,
				default: function() {
					return []
				}
			},
			importArea: {
				type: String,
				default: function() {
					return ""
				}
			},
			emptyText: {
				type: String,
				default: function() {
					return "暂无数据"
				}
			},
			showEmpty: {
				type: Boolean,
				default: function() {
					return false
				}
			},
			hasMinHeight: {
				type: Boolean,
				default: function() {
					return true
				}
			},
		},
		data() {
			return {}
		},
		beforeRouteEnter(to,from,next){
			console.log(from,next)
			to.meta.keepAlive = true
			if(from.path!=='/mb/activity/detail'){
				next(vm=>{
					vm.reload()
				})
			}else{
				next()
			}
		},
		beforeRouteLeave(to,from,next){
			console.log(to,from,'from')
			if(from.path=='/mb/activity/detail'){
			to.meta.keepAlive = true
			next()
			}else{
				to.meta.keepAlive = false
				next()
			}
		},
		methods: {
			dateFormat(item, intDate) {
				if (intDate) {
					if(item.postType == 'C_FIX') {
						let newDate = new Date(intDate)
						let year = newDate.getFullYear()
						let month = newDate.getMonth() + 1
						return `${year}-${month}`
					} else {
						return intDate.replace(":00", "")
					}
				} else {
					return ""
				}
			},
			toJobDetail(item) {
				if (this.importArea == 'student') {
					this.$router.push({
						path: `/mb/activity/detail?id=${item.id}`
					})
				} else if (this.importArea == 'jobhour') {
					this.$router.push({
						path: `/mb/activity/detail?id=${item.postId}`
					})
				} else if (this.importArea == 'teacher') {
					this.$router.push({
						path: `/mb/manhour/detail?id=${item.id}`
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.flex-box{
		display: flex;
		justify-content: space-between;
		align-items: center;
		.total-time{
			font-size:24px;
			color:#606266;
		}
	}
	.activity-min-height {
		min-height: 7rem;
	}

	.activity-list {

		.activity-list-item {
			padding: 0.28rem 0.28rem;
			width: 6.94rem;
			background: #FFFFFF;
			box-shadow: 0rem 0.04rem 0.16rem rgba(220, 223, 230, 0.2000);
			border-radius: 0.12rem;
			margin: 0 auto 0.165rem auto;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;

			.list-item-top {
				display: flex;
				flex-direction: column;
				border-bottom: 1px solid #DCDFE6;

				.item-top-title {
					display: flex;
					align-items: center;
					justify-content: space-between;

					.top-title-status {
						width: 0.84rem;
						height: 0.32rem;
					}
				}

				.item-top-title :nth-child(1) {
					font-size: 0.28rem;
					font-family: PingFang SC;
					font-weight: bold;
					line-height: 0.4rem;
					color: #303133;
				}

				.item-top-tag {
					display: inline-block;
					margin-right:0.08rem;
					min-width: 1.04rem;
					font-size: 0.2rem;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 0.32rem;
					color: #4392FA;
					background-color: rgba(67, 146, 250, 0.1);
					text-align: center;
					border-radius: 0.04rem;
					margin: 0.08rem 0 0.2rem 0;
					padding:0 0.08rem;
				}
			}

			.list-item-bottom {
				display: flex;
				align-items: center;
				margin-top: 0.14rem;

				.item-bottom-step {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					margin-right: 0.12rem;

					.bottom-step-circle1 {
						width: 0.12rem;
						height: 0.12rem;
						background: #15D2B7;
						border-radius: 50%;
					}

					.bottom-step-line {
						width: 0.02rem;
						height: 0.3rem;
						background-color: #DCDFE6;
					}

					.bottom-step-circle2 {
						width: 0.12rem;
						height: 0.12rem;
						background: #FFAE00;
						border-radius: 50%;
					}
				}

				.item-bottom-content {
					display: flex;
					flex-direction: column;

					span {
						margin-top: 0.06rem;
						font-size: 0.24rem;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 0.34rem;
						color: #909399;
					}
				}
			}
		}
	}

	.activity-list-empty {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 1.6rem 0;

		img {
			width: 2.8rem;
			height: 2.8rem;
			margin-bottom: 0.4rem;
		}

		span {
			font-size: 0.28rem;
			font-family: PingFang SC-Regular, PingFang SC;
			font-weight: 400;
			color: #909399;
			line-height: 0.4rem;
		}
	}
</style>
